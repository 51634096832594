define("adept-iq/pods/components/file-upload/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notifications: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    workspace: Ember.inject.service(),
    fileName: '',
    lastProgressState: '',

    init() {
      this._super(...arguments);

      this.selectedFile = null;
      this.isUploading = false;
    },

    actions: {
      triggerFileInput() {
        const fileInput = document.getElementById(this.fileInputId);

        const handleClick = event => {
          event.stopPropagation();
        }; // Prevent the event from bubbling up


        fileInput.addEventListener('click', handleClick);
        fileInput.click();
        fileInput.removeEventListener('click', handleClick);
      },

      // eslint-disable-next-line consistent-this
      handleFileChange(event) {
        const file = event.target.files[0]; // eslint-disable-next-line consistent-this

        const me = this;

        if (file) {
          const reader = new FileReader();
          this.set('fileName', file.name); // Closure to capture the file information.

          reader.onload = function () {
            return function (e) {
              const fileData = e.target.result;
              me.set('selectedFile', fileData);
              me.send('uploadFile');
            };
          }(file);

          reader.readAsArrayBuffer(file); // Reset the file input element

          event.target.value = '';
        }
      },

      async uploadFile() {
        const notifications = this.get('notifications');
        const file = this.get('selectedFile');
        const isUploading = this.get('isUploading');

        if (isUploading) {
          notifications.warning('An upload is already in progress. Please wait.');
          return;
        }

        try {
          if (file) {
            this.set('isUploading', true);
            this.onSuccess(file);
          }
        } catch (error) {
          notifications.warning(`Failed to upload the file: ${this.get('fileName')}. Please try again.`); // eslint-disable-next-line no-console

          console.log(error);
        } finally {
          this.set('isUploading', false);
        }
      }

    }
  });

  _exports.default = _default;
});