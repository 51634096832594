define("adept-iq/pods/components/iq-widgets/stops-widget/component", ["exports", "adept-iq/pods/components/iq-widgets/ember-react-widget/component", "adept-iq/pods/components/iq-widgets/stops-widget/scheduleConfig", "lodash", "adept-iq/utils/find-row-record", "adept-iq/utils/rql-generators", "adept-iq/mixins/async-schedule-operation", "adept-iq/mixins/version-check", "adept-iq/config/api-urls"], function (_exports, _component, _scheduleConfig, _lodash, _findRowRecord, _rqlGenerators, _asyncScheduleOperation, _versionCheck, _apiUrls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const stopTypeMap = {
    'pick': 'Pick',
    'drop': 'Drop',
    'pulloutGarage': 'Pullout Garage',
    'pullinGarage': 'Pullin Garage'
  };
  const INCLUDES = [];

  var _default = _component.default.extend(_asyncScheduleOperation.default, _versionCheck.default, {
    classNames: ['stops-widget'],
    editModal: Ember.inject.service(),
    dragCoordinator: Ember.inject.service(),
    workspace: Ember.inject.service(),
    activeContext: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),
    tooltip: Ember.inject.service(),
    user: Ember.inject.service(),
    permissionLayer: Ember.inject.service(),
    config: _scheduleConfig.default,
    defaultIncludes: INCLUDES,
    sourceRowId: null,
    dragTarget: null,
    dynamicSingleActions: Ember.computed('table.rows.@each.{selected}', 'selectedWidgetRecord', 'isLoading', function () {
      return this._dynamicScheduleActions();
    }),
    dragRowGroup: Ember.computed('row', function () {
      return this.get('table.rows');
    }).readOnly(),
    dragDirection: Ember.computed('dragTarget', 'sourceRowId', function () {
      const targetRow = this.get('dragTarget');

      if (targetRow) {
        const rows = this.get('dragRowGroup');
        const targetIdx = rows.indexOf((0, _findRowRecord.findRowRecordByElement)(targetRow, rows));
        const sourceIdx = rows.indexOf(rows.findBy('rowId', this.get('sourceRowId')));
        return sourceIdx - targetIdx;
      }

      return null;
    }).readOnly(),
    dragOverClass: Ember.computed('dragDirection', function () {
      const direction = this.get('dragDirection') < 0 ? 'below' : 'above';
      return ` drag-target-${direction} drag-target`;
    }),

    init() {
      this.set('config', _scheduleConfig.default);

      this._super(...arguments);
    },

    cursorWait() {
      Ember.$('html,body').css('cursor', 'wait');
    },

    cursorDefault() {
      Ember.$('html,body').css('cursor', 'default');
    },

    fetchDataQuery() {
      return this._super(...arguments).then(records => {
        // if it is a trip active context
        // only include the pick and drop stops
        if (this.get('activeContext.topActiveContext').tripActiveContext()) {
          return records.filter(record => {
            return ['pick', 'drop'].includes(record.get('stopType'));
          });
        }

        return records;
      });
    },

    calculateTimeQuery() {
      const startDate = this.get('workspace.startDate');
      const endDate = this.get('workspace.endDate');
      const startTime = startDate.getTime();
      const endTime = endDate.getTime();
      const dateRangeFilterQuery = (0, _rqlGenerators.dateBetweenRQLUsingFormat)('promiseTime', [startTime, endTime], 'YYYY-MM-DDTHH:mm:ss.SSS');
      return dateRangeFilterQuery;
    },

    actions: {
      onColumnClick() {
        this._super(...arguments);
      },

      onSearchButtonClick() {
        this._super(...arguments);
      },

      onFilterButtonClick() {
        this._super(...arguments);
      },

      async onRowRightClick() {
        return this._super(...arguments);
      },

      setAwsFileDownloadInstance(instance) {
        this.set('awsFileDownloadInstance', instance);
      },

      async generateScheduleManifest() {
        try {
          const currentSchedule = this.get('workspace.currentSchedule');
          const session = this.get('session');
          const token = session.data.authenticated.token;

          if (currentSchedule && currentSchedule.status === 'scheduled') {
            const scheduleId = currentSchedule.id;
            const url = `${_apiUrls.API.schedulingService.host}/schedule-manifest/${scheduleId}`;
            const response = await fetch(url, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              }
            });
            return response.ok;
          }
        } catch (error) {
          console.error('Fetch error:', error); // eslint-disable-line no-console

          return false;
        }
      }

    },

    didInsertElement() {
      this._super(...arguments);

      this._initTableActions();
    },

    dragStart(event) {
      const row = (0, _findRowRecord.findRowRecordByElement)(event.target, this.get('dragRowGroup'));
      if (Ember.isBlank(row)) return;
      this.set('sourceRowId', row.get('rowId'));
      const dragId = Ember.guidFor(row);
      this.get('dragCoordinator').setSourceRow(dragId, row, 'stops');
      event.dataTransfer.setData('text', dragId);
    },

    dragOver(event) {
      event.preventDefault();
      if (this.get('dragCoordinator.widgetType') !== 'stops') return;
      const rowElement = (0, _findRowRecord.findRowElement)(event.target); // User might drag over other widget elements.

      if (Ember.isBlank(rowElement)) {
        return;
      }

      this.set('dragTarget', rowElement);
      this.$(`#${rowElement.id}`).addClass(this.get('dragOverClass'));
    },

    dragLeave(event) {
      event.preventDefault();
      const rowElement = (0, _findRowRecord.findRowElement)(event.target); // User might drag over other widget elements.

      if (Ember.isBlank(rowElement)) {
        return;
      }

      this.$(`#${rowElement.id}`).removeClass(['drag-target-above', 'drag-target-below', 'drag-target']);
    },

    drop(event) {
      if (this.get('dragCoordinator.widgetType') !== 'stops') return;
      const rowElement = (0, _findRowRecord.findRowElement)(event.target); // User might drop onto other widget elements.

      if (Ember.isBlank(rowElement)) {
        return;
      }

      this.$(`#${rowElement.id}`).removeClass(['drag-target-above', 'drag-target-below', 'drag-target']);
      const table = this.get('table');
      const rows = this.get('dragRowGroup'); // let sourceRowRecord = rows.findBy('rowId', this.get('sourceRowId'));
      // // User might drop elements that are not Stops widget rows.
      // if (isBlank(sourceRowRecord)) {
      //   return;
      // }

      const sourceRowRecord = this.get('dragCoordinator').getSourceRow(event.dataTransfer.getData('text'));
      if (Ember.isBlank(sourceRowRecord)) return;
      const targetRowRecord = (0, _findRowRecord.findRowRecordByElement)(event.target, rows);

      const _rows = rows.toArray();

      const targetRowIdx = _rows.indexOf(targetRowRecord) + (this.get('dragDirection') < 0 ? 0 : 0);
      event.dataTransfer.dropEffect = 'move';
      event.preventDefault();
      event.stopPropagation();
      table.notifyPropertyChange('rows');

      _rows.removeObject(sourceRowRecord);

      _rows.insertAt(targetRowIdx, sourceRowRecord);

      const result = this.doReorder(sourceRowRecord, targetRowRecord);

      if (result) {
        rows.setObjects(_rows);
      }

      table.notifyPropertyChange('rows');
    },

    dragEnd() {
      this.set('sourceRowId', null);
      this.set('dragTarget', null);
      this.get('dragCoordinator').clearSourceRows();
    },

    _initTableActions() {
      this.set('tableActions', [{
        id: 'download-csv',
        name: 'CSV Format',
        action: () => {
          this.downloadCsvSchedule();
        }
      }, {
        id: 'download-json',
        name: 'JSON Format',
        action: () => {
          this.downloadJsonSchedule();
        }
      }]);
    },

    async downloadCsvSchedule() {
      const awsFileDownloadInstance = this.awsFileDownloadInstance;
      const notifications = this.get('notifications');

      if (awsFileDownloadInstance && awsFileDownloadInstance.actions.triggerFileDownload) {
        try {
          await awsFileDownloadInstance.actions.triggerFileDownload.call(awsFileDownloadInstance);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('CSV Fetch error:', error);
          notifications.warning('Unable To Download Schedule');
        }
      }
    },

    async downloadJsonSchedule() {
      const notifications = this.get('notifications');
      const currentSchedule = this.get('workspace.currentSchedule');
      const session = this.get('session');

      const token = _lodash.default.get(session, 'data.authenticated.token');

      if (_lodash.default.get(currentSchedule, 'status') !== 'scheduled') return false;

      try {
        this.workspace.set('isGlobalSpinnerVisible', true);
        const filter = `and(eq(scheduleId,${currentSchedule.id}),eq(operationType,'raasGenerate'))`;
        const encodedFilter = encodeURIComponent(filter);
        const url = `${_apiUrls.API.schedulingService.host}/schedule-async-operation?filter=${encodedFilter}`;
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        });

        if (!response.ok) {
          notifications.warning('Unable To Download Schedule');
          return false;
        }

        const sortedData = _lodash.default.orderBy((await response.json()).data, ['id'], ['desc']);

        const firstDataItem = _lodash.default.head(sortedData);

        const parsedResults = JSON.parse(_lodash.default.get(firstDataItem, 'attributes.results', '{}'));
        const resultsJson = JSON.parse(parsedResults.message || '{}');
        const blob = new Blob([JSON.stringify(resultsJson, null, 2)], {
          type: 'application/json'
        });
        const link = Object.assign(document.createElement('a'), {
          href: URL.createObjectURL(blob),
          download: 'manifest.json'
        });
        link.click();
        URL.revokeObjectURL(link.href);
        return true;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('JSON Fetch error:', error);
        notifications.warning('Unable To Download Schedule');
        return false;
      } finally {
        this.workspace.set('isGlobalSpinnerVisible', false);
      }
    },

    _dynamicScheduleActions() {
      const activityLog = this.get('activityLog');
      const row = this.getSelectedCoreModel();
      if (!row) return []; //const selectedWidgetRecord = this.get('selectedWidgetRecord');

      const genericActions = [activityLog];
      return genericActions;
    },

    applyTransformationData(widgetRecords) {
      if (widgetRecords.transformed) {
        return widgetRecords;
      }

      widgetRecords.forEach(widgetRecord => {
        widgetRecord.stopTypeDisplay = stopTypeMap[widgetRecord.stopType] || '';
      });

      const recordsByRoute = _lodash.default.groupBy(widgetRecords.filter(record => record.routeId), 'routeId');

      _lodash.default.forEach(recordsByRoute, records => {
        //Compute Ordinal Value for pullinGarage
        let lastOrdinal = _lodash.default.maxBy(records, 'plannedRouteOrdinal')?.plannedRouteOrdinal || 0;

        const orderedRecords = _lodash.default.sortBy(records, record => {
          if (record.stopType === 'pullinGarage' && !record.plannedRouteOrdinal) {
            record.plannedRouteOrdinal = ++lastOrdinal;
          }

          return record.plannedRouteOrdinal || Number.MAX_VALUE;
        }); // Compute currPassengerCount


        let currPassengerCount = 0;
        orderedRecords.forEach(record => {
          const pickCount = record.pickPassengerCount || 0;
          const dropCount = record.dropPassengerCount || 0;
          currPassengerCount += pickCount - dropCount;
          record.currPassengerCount = currPassengerCount;
        });
      });

      return widgetRecords;
    },

    // eslint-disable-next-line no-unused-vars
    rowStyleHandler(data, rowIndex, columnInfo, columnIndex, selectedRowIndex, selectedColumnIndex, columnValue, currRowData) {
      const rowStyleHandler = '';
      return rowStyleHandler;
    },

    canFurtherSelectionAllowed() {
      const gridConfig = this.get('config');
      const maxLimitByRouteSelection = gridConfig.maxLimitByRouteSelection;
      const maxLimitWithoutRouteAssociation = gridConfig.maxLimitWithoutRouteAssociation;
      const selectedStopRecords = this.get('widgetService').getSelectedWidgetRecordsByModel('stopWidget');
      const selectedRouteSet = new Set();
      selectedStopRecords.forEach(data => {
        if (data.routeId) {
          selectedRouteSet.add(data.routeId);
        }
      });

      if (selectedRouteSet.size >= maxLimitByRouteSelection || selectedStopRecords.length >= maxLimitWithoutRouteAssociation) {
        return false;
      }

      return true;
    }

  });

  _exports.default = _default;
});