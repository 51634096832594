define("adept-iq/pods/components/file-upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bSChCcpy",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"file-upload\"],[9],[0,\"\\n  \"],[7,\"input\"],[12,\"id\",[21,\"fileInputId\"]],[12,\"onchange\",[27,\"action\",[[22,0,[]],\"handleFileChange\"],null]],[11,\"hidden\",\"\"],[11,\"type\",\"file\"],[9],[10],[0,\"\\n  \"],[7,\"button\"],[12,\"class\",[21,\"buttonClass\"]],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"triggerFileInput\"],null]],[12,\"disabled\",[27,\"or\",[[23,[\"isJSONUploading\"]],[23,[\"disabled\"]]],null]],[9],[0,\"\\n   \"],[14,1],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/file-upload/template.hbs"
    }
  });

  _exports.default = _default;
});