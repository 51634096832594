define("adept-iq/utils/translator", ["exports", "xlsx", "papaparse", "moment"], function (_exports, _xlsx, _papaparse, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.readExcelFile = readExcelFile;
  _exports.translator = void 0;
  const csvColumns = {
    job: ['jobId', 'pickLat', 'pickLng', 'pickDwell', 'pickName', 'dropLat', 'dropLng', 'dropDwell', 'dropName', 'requestTime', 'anchor', 'riderId', 'rideSharingMethod', 'capacityDemand'],
    vehicle: ['vehicleId', 'vehicleTypeName', 'startLocationName', 'startLocationLat', 'startLocationLng', 'endLocationName', 'endLocationLat', 'endLocationLng']
  };
  const templateSheets = {
    job: _xlsx.default.utils.aoa_to_sheet([['Field #', 'Field Name', 'Field Data Type', 'Description', 'Required'], [1, 'jobId', 'text(255)', 'Unique job identifier.', 'Yes'], [2, 'pickLat', 'WGS84 coordinate', 'Pick latitude.', 'Yes'], [3, 'pickLng', 'WGS84 coordinate', 'Pick longitude.', 'Yes'], [4, 'pickDwell', 'number', 'Load time specified in minutes.', 'Yes'], [5, 'pickName', 'text(255)', 'Pick address or name of the place.', 'Yes'], [6, 'dropLat', 'WGS84 coordinate', 'Drop latitude.', 'Yes'], [7, 'dropLng', 'WGS84 coordinate', 'Drop longitude.', 'Yes'], [8, 'dropDwell', 'number', 'Unload time specified in minutes.', 'Yes'], [9, 'dropName', 'text(255)', 'Drop address or name of the place.', 'Yes'], [10, 'requestTime', 'ISO timestamp', 'Trip request time.', 'Yes'], [11, 'anchor', 'text(255)\nTwo options: pick or drop', 'Indicates whether the request time refers to the pick or drop.\nSet to ‘pick’ for pick-anchored trips or ‘drop’ for drop-anchored trips.', 'Yes'], [12, 'riderId', 'number', 'Rider identifier.', 'Yes'], [13, 'rideSharingMethod', 'number', 'Set as follows:\n0 = rider sharing is allowed\n1 = direct trip (no stops allowed between pick and drop)\n2 = no ride sharing (direct trip, vehicle must be empty when picking up rider)', 'Yes'], [14, 'capacityDemand', 'number', '>=1', 'Yes']]),
    vehicle: _xlsx.default.utils.aoa_to_sheet([['Field #', 'Field Name', 'Field Data Type', 'Comments', 'Required'], [1, 'vehicleId', 'text(64)', 'Unique vehicle identifier.', 'Yes'], [2, 'vehicleTypeName', 'text(255)', 'Name of the vehicle type configured in RaaS.', 'Yes'], [3, 'startLocationName', 'text(255)', 'Start location address or name of the place.', 'Yes'], [4, 'startLocationLat', 'WGS84 coordinate', 'Start location latitude.', 'Yes'], [5, 'startLocationLng', 'WGS84 coordinate', 'Start location longitude.', 'Yes'], [6, 'endLocationName', 'text(255)', 'End location address or name of the place.', 'Yes'], [7, 'endLocationLat', 'WGS84 coordinate', 'End location latitude.', 'Yes'], [8, 'endLocationLng', 'WGS84 coordinate', 'End location longitude.', 'Yes']]),
    manifest: _xlsx.default.utils.aoa_to_sheet([['Field #', 'Name', 'Type', 'Comments'], [1, 'vehicleId', 'text(64)', 'Unique vehicle identifier'], [2, 'jobId', 'text(255)', 'Unique job identifier'], [3, 'stopType', 'text(255)', "'pick'/'drop' for trips, 'start'/'end' for garages"], [4, 'stopEta', 'ISO timestamp'], [5, 'status', 'text(255)', "Trip status can be 'scheduled' or 'unassigned'. The vehicleId is blank for 'unassigned' trips"], [6, 'statusReason', 'text(4096)', "Seet for 'unassigned' trips only."]])
  }; // Helper functions remain as-is, adapted for Ember if necessary

  function getDos(sandboxJobs, timezone) {
    const allDates = sandboxJobs.reduce((agg, j) => {
      const req = (0, _moment.default)(j.requestTime).tz(timezone);
      const date = req.startOf('day').format();

      if (!agg[date]) {
        agg[date] = 0;
      }

      agg[date]++;
      return agg;
    }, {});
    return Object.entries(allDates).reduce((max, _ref) => {
      let [d, n] = _ref;

      if (!max.n || n > max.n) {
        max.d = {
          start: d,
          end: (0, _moment.default)(d).tz(timezone).add(32, 'hours').format()
        };
        max.n = n;
      }

      return max;
    }, {}).d;
  }

  function streamCsvFile(fileStream, model) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = e => {
        _papaparse.default.parse(e.target.result, {
          header: true,
          skipEmptyLines: true,
          complete: results => {
            const rows = results.data.map(row => csvColumns[model].reduce((filtered, key) => {
              filtered[key] = row[key];
              return filtered;
            }, {}));
            resolve(rows);
          },
          error: reject
        });
      };

      reader.onerror = reject;
      reader.readAsText(fileStream);
    });
  }

  function readCsvFile(fileBuffer, model) {
    return streamCsvFile(fileBuffer, model);
  }

  function readExcelFile(fileBuffer, model) {
    const workbook = _xlsx.default.read(fileBuffer, {
      type: 'buffer'
    });

    const dataWorksheet = workbook.Sheets.Data;

    if (!dataWorksheet) {
      throw new Error('Sheet "Data" not found in the Excel file');
    }

    return _xlsx.default.utils.sheet_to_json(dataWorksheet, {
      header: csvColumns[model],
      range: 1
    });
  }

  function readFile(_ref2) {
    let {
      fileBuffer,
      fileExtension,
      model
    } = _ref2;

    if (fileExtension === 'csv') {
      return readCsvFile(fileBuffer, model);
    }

    if (fileExtension === 'xlsx') {
      return readExcelFile(fileBuffer, model);
    }

    throw new Error('Attempting to readFile for unsupported extension');
  }

  function writeCsvFile(json) {
    const model = json[0].vehicleId ? 'vehicle' : 'job';
    const headers = csvColumns[model];
    const file = [headers.join(',')];
    json.forEach(j => file.push(Object.values(j).join(',')));
    return file.join('\n');
  }

  function writeExcelFile(json) {
    const model = json[0].vehicleId ? 'vehicle' : 'job';
    const headers = csvColumns[model];
    const aoa = [headers].concat(json.map(j => Object.values(j)));

    const workbook = _xlsx.default.utils.book_new();

    const dataSheet = _xlsx.default.utils.aoa_to_sheet(aoa);

    _xlsx.default.utils.book_append_sheet(workbook, dataSheet, 'Data');

    _xlsx.default.utils.book_append_sheet(workbook, templateSheets[model], 'Template');

    return _xlsx.default.write(workbook, {
      type: 'buffer',
      bookType: 'xlsx'
    });
  }

  function writeFile(json) {
    let extension = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'csv';

    switch (extension) {
      case 'csv':
        return writeCsvFile(json);

      case 'xlsx':
        return writeExcelFile(json);

      default:
        throw new Error('Unsupported file type');
    }
  } // When converting vehicles from 'sandbox' to RaaS
  // - In Sandbox format vehicles do not have shifts, so we assume all vehicles have a shift covering
  //   the entire scheduling date [00:00, 08:00+1] (midnight to 8am the next day). The service date
  //   is inferred by taking a majority vote over the jobs 'requestTime'
  // - In Sandbox vehicle have 'vehicleTypeName', to get a numerical capacity we have to lookup that
  //   name in the config service. However since the Sandbox does not understand capacity type we
  //   just make one up (called 'seat') and whatever exists in config service gets assigned to that
  //   'seat' capacity.
  // - Sandbox has no route breaks, no lifo, no skills, no limits, no lastKnownLocation


  function sandboxVehicleToRaas(sandboxVehicles, vehicleCapacities, dos) {
    return sandboxVehicles.map(v => {
      const cap = vehicleCapacities[v.vehicleTypeName];

      if (!cap) {
        throw new Error(`Missing vehicle capacity for ${v.vehicleTypeName}`);
      }

      return {
        id: Number(v.vehicleId),
        shifts: [{
          start: {
            time: `${dos.start}`,
            location: [v.startLocationLat, v.startLocationLng]
          },
          end: {
            time: `${dos.end}`,
            location: [v.endLocationLat, v.endLocationLng]
          }
        }],
        capacities: [{
          name: 'seat',
          units: cap
        }]
      };
    });
  } // When converting jobs from 'sandbox' to RaaS
  // - In Sandbox format only the job has an id, the pick/drop do not. So we make them up.
  // - In Sandbox there is no timezone and the only time given is the job.requestTime. Unfortunately
  //   that time is always given in UTC so we need to have the timezone specified externally
  // - In Sandbox there is no 'service type'. So we assume that a 30 minute window for all
  //   pick-anchored trips get the service window [treq, treq + 30]
  //   drop-anchored trips get the service window [treq - 30, treq]
  // - In Sandbox the trips only specify the demand 'count', not the demand 'type'. So we
  //   make up a type called 'seat' and assign the demand to that type


  function sandboxJobToRaas(sandboxJobs, problemTimezone) {
    // rideSharingMethod 0/1/2, riderId number
    return sandboxJobs.map((j, idx) => ({
      id: Number(j.jobId),
      pickups: [{
        id: 2 * idx + 1,
        // Sandbox has no pickId
        location: [j.pickLat, j.pickLng],
        serviceWindows: j.anchor === 'pick' ? [[(0, _moment.default)(j.requestTime).tz(problemTimezone).format(), (0, _moment.default)(j.requestTime).tz(problemTimezone).add(30, 'minutes').format()]] : j.dummy,
        duration: (j.pickDwell || 0) * 60,
        // Sandbox dwell is in minutes, raas in seconds
        demand: [{
          name: 'seat',
          'units': j.capacityDemand
        }]
      }],
      deliveries: [{
        id: 2 * idx + 2,
        // Sandbox has no dropId
        location: [j.dropLat, j.dropLng],
        serviceWindows: j.anchor === 'drop' ? [[(0, _moment.default)(j.requestTime).tz(problemTimezone).subtract(30, 'minutes').format(), (0, _moment.default)(j.requestTime).tz(problemTimezone).format()]] : j.dummy,
        duration: (j.dropDwell || 0) * 60,
        // Sandbox dwell is in minutes, raas in seconds
        demand: [{
          name: 'seat',
          'units': j.capacityDemand
        }]
      }]
    }));
  } // When converting a RAAS problem to 'sandbox' format
  // - RAAS vehicles have no garage 'name' so we have to make them up.
  // - RAAS vehicles have no 'vehicleTypeName' so we have to come up with one. The strategy is to
  //   look at the capacity count and attempt to reverse engineer the vehicleTypeName by looking at
  //   the config service. There can be ambiguity with this method because multiple vehicleCapConfig
  //   in the config service database can have the same count.
  // - RAAS picks/drops have no 'name' so we have to make them up.
  // - RAAS has no riderId, so we have to make them up
  // - RAAS has no rideSharingMethod so we use 0 (ride sharing allowed) for all


  function raasProblemToSandbox(raasJson, vehicleCapacities) {
    const problem = raasJson.problem;
    const vehicles = problem.fleet.map(v => {
      const shift = v.shifts[0];
      const startLocation = shift.start.location;
      const endLocation = shift.end.location;
      const cap = v.capacities[0].units; // Find a config service 'vehicle capacity config' that has the same count as the RAAS cap

      const vehicleType = Object.entries(vehicleCapacities).reduce((t, _ref3) => {
        let [n, c] = _ref3;

        if (c === cap) {
          return n;
        }

        return t;
      }, 'unknownVehicleType');
      return {
        vehicleId: v.id,
        vehicleTypeName: vehicleType,
        // May be unreliable
        startLocationName: startLocation ? `Pullout garage at ${startLocation}` : 'Floating garage',
        // Does not exists in RaaS
        startLocationLat: startLocation ? startLocation[0] : startLocation,
        startLocationLng: startLocation ? startLocation[1] : startLocation,
        endLocationName: endLocation ? `Pull in garage at ${endLocation}` : 'Floating garage',
        // Does not exists in RaaS
        endLocationLat: endLocation ? endLocation[0] : endLocation,
        endLocationLng: endLocation ? endLocation[1] : endLocation
      };
    });
    const jobs = problem.jobs.map((j, idx) => {
      const pick = j.pickups[0];
      const drop = j.deliveries[0];
      const anchor = pick.serviceWindows && pick.serviceWindows.length ? 'pick' : 'drop';
      const requestTime = anchor === 'pick' ? pick.serviceWindows[0][0] : drop.serviceWindows[0][0];
      const pickDuration = typeof pick.duration === 'number' ? pick.duration : (pick.duration.service || 0) + (pick.duration.fixed || 0);
      const dropDuration = typeof drop.duration === 'number' ? drop.duration : (drop.duration.service || 0) + (drop.duration.fixed || 0);
      return {
        jobId: j.id,
        pickLat: pick.location[0],
        pickLng: pick.location[1],
        pickDwell: Math.round(pickDuration / 60),
        // Raas duration in seconds, sandbox in minutes
        pickName: `Pick at ${pick.location}`,
        // Does not exists in RaaS
        dropLat: drop.location[0],
        dropLng: drop.location[1],
        dropDwell: Math.round(dropDuration / 60),
        // Raas duration in seconds, sandbox in minutes
        dropName: `Drop at ${drop.location}`,
        // Does not exists in RaaS
        requestTime,
        anchor,
        riderId: idx + 1,
        // does not exist in RaaS
        rideSharingMethod: 0,
        // does not exist in RaaS
        capacityDemand: pick.demand[0].units
      };
    });
    return {
      jobs,
      vehicles
    };
  }

  async function createRaasPayload(vehicleFileWithMetaData, jobFileWithMetaData, objective, problemTimezone, vehicleCapacities) {
    // Step 1: Convert the input CSV/Excel file buffers to JSON
    const sandboxVehicles = await readFile(vehicleFileWithMetaData);
    const sandboxJobs = await readFile(jobFileWithMetaData); // Step 2: Find the start/end date. This is inferred from the job request times.
    //         It is only needed because the 'sandbox' format does not specify shift
    //         time for its vehicles. So we 'hardcode' the shift to be the entire
    //         duration of the 'date of service'.

    const problemDates = getDos(sandboxJobs, problemTimezone); // Step 3: Convert from 'Sandbox JSON' to 'RAAS JSON'

    const fleet = sandboxVehicleToRaas(sandboxVehicles, vehicleCapacities, problemDates);
    const jobs = sandboxJobToRaas(sandboxJobs, problemTimezone); // Step 4: Return the converted payload

    return {
      problem: {
        fleet,
        jobs,
        objective,
        configuration: {
          timezone: problemTimezone
        }
      }
    };
  }

  function assembleRaasPayload(fleet, jobs, objective, problemTimezone) {
    return {
      problem: {
        fleet,
        jobs,
        objective,
        configuration: {
          timezone: problemTimezone
        }
      }
    };
  } // When converting a RAAS solution to 'sandbox' format
  // - RAAS solution does not have dwell
  // - RAAS solution.unassigned does not keep stop type ('pick'/'drop'). However if the problem was
  //   created by this library then the task id is odd for picks and even for drops.
  // - RAAS solution.unassigned does not differentiate between illegal/waitlisted. To figure out which
  //   it is we may have to parse solution.unassigned.reason[.].description
  // - RAAS solution does not include garage ETAs


  function raasSolutionToSandbox(raasResponse) {
    const solution = raasResponse.solution;
    const rows = [];

    for (const route of solution.routes || []) {
      for (const shift of route.shifts || []) {
        for (const stop of shift.stops || []) {
          rows.push([route.vehicleId, stop.jobId, stop.type === 'pickup' ? 'pick' : 'drop', stop.eta, 0, // Dwell not in RAAS solution
          'scheduled']);
        }
      }
    }

    for (const ill of solution.unassigned || []) {
      const status = 'illegal';
      rows.push(['', // No vehicle id since the trip is not scheduled
      ill.id.job, ill.id.task % 2 ? 'pick' : 'drop', // only works for problem produced by this library
      '', // No ETA since the trip is not scheduled
      0, // Dwell not in RAAS solution
      status, ill.reason]);
    }

    return rows;
  }

  const translator = {
    readFile,
    writeFile,
    sandboxVehicleToRaas,
    sandboxJobToRaas,
    raasProblemToSandbox,
    createRaasPayload,
    assembleRaasPayload,
    raasSolutionToSandbox,
    getDos
  };
  _exports.translator = translator;
});