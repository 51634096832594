define("adept-iq/pods/components/menu-navbar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w4mJd266",
    "block": "{\"symbols\":[\"navItem\",\"@isMenuVisible\"],\"statements\":[[7,\"div\"],[11,\"class\",\"menu-navbar-wrapper\"],[9],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[28,[\"menu-navbar-container \",[27,\"if\",[[22,0,[\"isScheduleDashboard\"]],\"schedule-dashboard-navbar\"],null]]]],[9],[0,\"\\n      \"],[7,\"nav\"],[12,\"class\",[28,[\"menu-navbar \",[27,\"if\",[[22,0,[\"isScheduleDashboard\"]],\"space-evenly\"],null]]]],[9],[0,\"\\n\"],[4,\"if\",[[22,2,[]]],null,{\"statements\":[[4,\"each\",[[22,0,[\"navItems\"]]],null,{\"statements\":[[0,\"          \"],[7,\"button\"],[12,\"class\",[28,[\"btn nav-button \",[27,\"if\",[[22,1,[\"highlighted\"]],\"highlighted\"],null]]]],[9],[0,\"\\n            \"],[7,\"img\"],[12,\"src\",[22,1,[\"iconPath\"]]],[12,\"class\",[28,[[22,1,[\"scaleClass\"]]]]],[9],[10],[0,\"\\n            \"],[7,\"span\"],[11,\"class\",\"nav-button-text\"],[9],[1,[22,1,[\"label\"]],false],[10],[0,\"\\n          \"],[3,\"action\",[[22,0,[]],[22,1,[\"actionName\"]]]],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"          \"],[7,\"button\"],[11,\"class\",\"btn nav-button\"],[12,\"style\",[27,\"unless\",[[22,0,[\"navItems\",\"length\"]],\"box-shadow: 0px 2px 5px rgba(38, 50, 56, 0.50); background: linear-gradient(180deg, #E7F3F9 100%, #FFFFFF 0%);\"],null]],[9],[0,\"\\n            \"],[7,\"img\"],[12,\"src\",[28,[[27,\"if\",[[22,0,[\"navItems\",\"length\"]],[22,0,[\"iconPaths\",\"logout\"]],[22,0,[\"iconPaths\",\"sandboxLogout\"]]],null]]]],[11,\"class\",\"mr-2\"],[12,\"style\",[27,\"unless\",[[22,0,[\"navItems\",\"length\"]],\"width:20px;height:20px;\"],null]],[9],[10],[0,\"\\n            \"],[7,\"span\"],[11,\"class\",\"nav-button-text\"],[12,\"style\",[27,\"unless\",[[22,0,[\"navItems\",\"length\"]],\"color:#586368;\"],null]],[9],[0,\"Logout\"],[10],[0,\"\\n          \"],[3,\"action\",[[22,0,[]],\"logout\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/menu-navbar/template.hbs"
    }
  });

  _exports.default = _default;
});