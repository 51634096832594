define("adept-iq/adapters/route-template", ["exports", "adept-iq/adapters/-ss-schedulingService"], function (_exports, _ssSchedulingService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ssSchedulingService.default.extend({
    ajax1: Ember.inject.service('ajax'),
    session: Ember.inject.service(),
    store: Ember.inject.service(),

    bulkDelete() {
      const session = this.get('session');
      const store = this.get('store'); // Construct the API URL

      const url = `${this.host}/route-template?systemType=raas`; // Perform the DELETE request

      return this.get('ajax1').request(url, {
        method: 'DELETE',
        contentType: 'application/json',
        dataType: 'text',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      }).then(() => {
        // Unload all records of the relevant model from the store
        store.peekAll('route-template').forEach(record => {
          store.unloadRecord(record);
        }); // Unload all records of the relevant model from the store

        store.peekAll('route-template-availability').forEach(record => {
          store.unloadRecord(record);
        });
      });
    }

  });

  _exports.default = _default;
});