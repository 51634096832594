define("adept-iq/serializers/raas-api-call-record", ["exports", "adept-iq/serializers/-raasService"], function (_exports, _raasService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _raasService.default.extend({
    attrs: {},

    serialize() {
      const json = this._super(...arguments);

      return json;
    },

    normalize(modelClass, resourceHash) {
      const normalized = this._super(modelClass, resourceHash);

      return normalized;
    },

    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      let jsonApiPayload;

      if (Array.isArray(payload)) {
        jsonApiPayload = {
          data: payload.map(item => ({
            id: item.id,
            type: primaryModelClass.modelName,
            attributes: { ...item
            }
          }))
        };
      } else {
        jsonApiPayload = {
          data: {
            id: payload.id,
            type: primaryModelClass.modelName,
            attributes: { ...payload
            }
          }
        };
      }

      const normalizedResponse = this._super(store, primaryModelClass, jsonApiPayload, id, requestType);

      return normalizedResponse;
    }

  });

  _exports.default = _default;
});