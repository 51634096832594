define("adept-iq/pods/components/sandbox-setup/step1/component", ["exports", "adept-iq/config/icon-paths", "adept-iq/config/api-urls", "adept-iq/utils/translator"], function (_exports, _iconPaths, _apiUrls, _translator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    isXLSXFleetDownloaded: false,
    isXLSXFleetUploaded: false,
    isXLSXJobsDownloaded: false,
    isXLSXJobsUploaded: false,
    isJSONUploaded: false,
    XLSXFleetMetaData: null,
    XLSXJobsMetaData: null,
    JSONUploadData: null,
    // Computed property to determine if the upload button should be disabled
    isXLSXFleetUploadDisabled: Ember.computed('isXLSXFleetUploaded', function () {
      return this.isXLSXFleetUploaded;
    }),
    // Computed property to determine if the upload button should be disabled
    isXLSXJobsUploadDisabled: Ember.computed('isXLSXJobsUploaded', function () {
      return this.isXLSXJobsUploaded;
    }),
    isJSONUploadDisabled: Ember.computed('isJSONUploaded', function () {
      return this.isJSONUploaded;
    }),

    didInsertElement() {
      this._super(...arguments);
    },

    willDestroyElement() {
      this._super(...arguments);
    },

    get iconPaths() {
      return {
        sandbox: _iconPaths.default.actionMenu.sandbox,
        upload: _iconPaths.default.actionMenu.upload,
        download: _iconPaths.default.actionMenu.download,
        checkmark: _iconPaths.default.actionMenu.checkmark
      };
    },

    async getJsonPayload(fleetFileWithMetaData, jobFileWithMetaDta) {
      const problemTimezone = localStorage.getItem('userTimezone');
      const objective = 1;
      const vehicleCapacityConfigs = await this.get('store').findAll('vehicle-capacity-config');
      const vehicleTypeGroupCount = vehicleCapacityConfigs.reduce((agg, vcc) => {
        if (vcc.count) {
          if (!agg[vcc.vehicleTypeName]) {
            agg[vcc.vehicleTypeName] = {};
          }

          if (!agg[vcc.vehicleTypeName][vcc.group]) {
            agg[vcc.vehicleTypeName][vcc.group] = 0;
          }

          agg[vcc.vehicleTypeName][vcc.group] += vcc.count;
        }

        return agg;
      }, {});
      const vehicleCapacities = Object.entries(vehicleTypeGroupCount).reduce((agg, _ref) => {
        let [vehicleTypeName, groupCount] = _ref;
        const minGroup = Math.min(...Object.keys(groupCount).map(g => Number(g)));
        agg[vehicleTypeName] = groupCount[minGroup];
        return agg;
      }, {});
      return _translator.translator.createRaasPayload(fleetFileWithMetaData, jobFileWithMetaDta, objective, problemTimezone, vehicleCapacities);
    },

    async checkTasksCompletion() {
      const notifications = this.get('notifications');

      try {
        let jsonData = null;

        if (this.get('isXLSXJobsUploaded') && this.get('isXLSXFleetUploaded')) {
          jsonData = await this.getJsonPayload(this.get('XLSXFleetMetaData'), this.get('XLSXJobsMetaData'));
        }

        if (this.get('isJSONUploaded')) {
          jsonData = this.get('JSONUploadData');
        } // Notify parent component that both tasks are completed


        if (jsonData) {
          this.onTaskComplete(jsonData);
        }
      } catch (e) {
        notifications.warning('PLEASE TRY AGAIN. INVALID FILE');
        this.set('isXLSXJobsUploaded', false);
        this.set('isXLSXFleetUploaded', false);
        this.set('isJSONUploaded', false);
      }
    },

    actions: {
      handleXLSXFleetDownloadSuccess() {
        this.set('isXLSXFleetDownloaded', true);
      },

      handleXLSXJobsDownloadSuccess() {
        this.set('isXLSXJobsDownloaded', true);
      },

      async handleXLSXFleetUploadSuccess(fileData) {
        const XLSXFleetMetaData = {
          fileBuffer: fileData,
          fileExtension: 'xlsx',
          model: 'vehicle'
        };
        this.set('XLSXFleetMetaData', XLSXFleetMetaData);
        this.set('isXLSXFleetUploaded', true);
        await this.checkTasksCompletion();
      },

      async handleXLSXJobsUploadSuccess(fileData) {
        const XLSXJobsMetaData = {
          fileBuffer: fileData,
          fileExtension: 'xlsx',
          model: 'job'
        };
        this.set('XLSXJobsMetaData', XLSXJobsMetaData);
        this.set('isXLSXJobsUploaded', true);
        await this.checkTasksCompletion();
      },

      async handleJSONUploadSuccess(fileData) {
        this.set('isJSONUploaded', true);
        const text = new TextDecoder().decode(fileData);
        const jsonData = JSON.parse(text);
        this.set('JSONUploadData', jsonData);
        await this.checkTasksCompletion();
      },

      openApiDocumentation() {
        window.open(_apiUrls.API.apiDocs.host, '_blank');
      }

    }
  });

  _exports.default = _default;
});