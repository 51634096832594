define("adept-iq/pods/components/global-spinner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1Mu2hYYy",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"workspace\",\"spinnerImageUpdate\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"spinner-wrapper\"],[9],[0,\"\\n   \"],[7,\"img\"],[12,\"src\",[23,[\"workspace\",\"spinnerImageUpdate\"]]],[11,\"alt\",\"Loading...\"],[11,\"class\",\"spinner-gif\"],[9],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"workspace\",\"spinnerTextUpdate\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"spinner-text\"],[9],[1,[23,[\"workspace\",\"spinnerTextUpdate\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"i\"],[11,\"class\",\"fa fa-3x fa-circle-o-notch fa-spin\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "adept-iq/pods/components/global-spinner/template.hbs"
    }
  });

  _exports.default = _default;
});