define("adept-iq/serializers/vehicle-capacity-config", ["exports", "adept-iq/serializers/cs-config-model"], function (_exports, _csConfigModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _csConfigModel.default.extend({
    modelName: 'vehicle-capacity-config',

    normalizeArrayResponse() {
      const normalized = this._super(...arguments);

      if (Ember.isPresent(normalized.data)) {
        normalized.data.forEach(datum => {
          datum.attributes = datum.attributes || {};
          datum.relationships = datum.relationships || {};
          datum.attributes.vehicleTypeName = datum.attributes.vehicleType;
          const vehicleTypeId = datum.attributes.vehicleType;
          const vehicleCapacityTypeId = datum.attributes.vehicleCapacityType;
          delete datum.attributes.vehicleType;
          delete datum.attributes.vehicleCapacityType;
          datum.relationships.vehicleType = {
            data: {
              id: vehicleTypeId,
              type: 'vehicle-type'
            }
          };
          datum.relationships.vehicleCapacityType = {
            data: {
              id: vehicleCapacityTypeId,
              type: 'vehicle-capacity-type'
            }
          };
        });
      }

      return normalized;
    },

    normalizeSingleResponse() {
      const normalized = this._super(...arguments);

      if (Ember.isPresent(normalized.data)) {
        normalized.data.attributes = normalized.data.attributes || {};
        normalized.data.relationships = normalized.data.relationships || {};
        const vehicleTypeId = normalized.data.attributes.vehicleType;
        const vehicleCapacityTypeId = normalized.data.attributes.vehicleCapacityType;
        delete normalized.data.attributes.vehicleType;
        delete normalized.data.attributes.vehicleCapacityType;
        normalized.data.relationships.vehicleType = {
          data: {
            id: vehicleTypeId,
            type: 'vehicle-type'
          }
        };
        normalized.data.relationships.vehicleCapacityType = {
          data: {
            id: vehicleCapacityTypeId,
            type: 'vehicle-capacity-type'
          }
        };
      }

      return normalized;
    }

  });

  _exports.default = _default;
});