define("adept-iq/adapters/vehicle", ["exports", "adept-iq/adapters/-vehicleService"], function (_exports, _vehicleService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _vehicleService.default.extend({
    ajax1: Ember.inject.service('ajax'),
    session: Ember.inject.service(),
    store: Ember.inject.service(),

    getPath(url, defaults) {
      // eslint-disable-next-line no-useless-escape
      var reUrlPath = /(?:\w+:)?\/\/[^\/]+([^?#]+)/;
      var urlParts = url.match(reUrlPath) || [url, defaults];
      return urlParts.pop();
    },

    // eslint-disable-next-line no-unused-vars
    urlForFindAll(modelName) {
      return this._super(...arguments);
    },

    bulkDelete() {
      const session = this.get('session');
      const store = this.get('store');
      const url = `${this.host}/vehicle?systemType=raas`;
      return this.get('ajax1').delete(url, {
        method: 'DELETE',
        contentType: 'application/json',
        dataType: 'text',
        headers: {
          'Authorization': `Bearer ${session.data.authenticated.token}`
        }
      }).then(() => {
        store.peekAll('vehicle').forEach(record => {
          store.unloadRecord(record);
        });
      });
    }

  });

  _exports.default = _default;
});